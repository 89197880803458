import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
	{ path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},
	{ path: 'infravision', loadChildren: () => import('./infravision/infravision.module').then(m => m.InfravisionModule)  },
	{ path: 'gallery', loadChildren: () => import('./gallery/gallery.module').then(m => m.GalleryModule)  },
	{ path: 'contact-us', loadChildren: () => import('./contact-us/module').then(m => m.PageModule),  },
	{ path: 'dean-schneider', loadChildren: () => import('./dean-schneider/module').then(m => m.PageModule)  },
	{ path: 'bellami', loadChildren: () => import('./bellami/module').then(m => m.PageModule)  },
	{ path: 'about/:id', loadChildren: () => import('./about/module').then(m => m.PageModule)  },
	// { path: 'our-process', loadChildren: () => import('./about/module').then(m => m.PageModule) },
	{ path: '404', loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)  },
	{ path: '**', redirectTo: '404', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
	scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
