import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './common/common.service';
import { Subscription } from 'rxjs';
declare var $: any;
import { NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = '61sunray';

  subscription: Subscription;
  public popUpType: any = "";
  public formGroup: any;
  public showLoaderGif: boolean = false;
  public caseForm: any = { agree_tc: false };
  public caseType: any = "";
  public usname: any = '';
  public showThank: any = false;
  public popupType: any = null

  constructor(private commonService: CommonService, private router: Router, private cdr: ChangeDetectorRef, public httpClient: HttpClient) {
    try {
      if (this.commonService.isBrowser()) {
        $(document).ready(function () {
          window.history.pushState(null, "", window.location.href);
          window.onpopstate = function () {
            window.history.pushState(null, "", window.location.href);
          };
        });

        this.subscription = commonService.missionAnnounced$.subscribe(e => {
          try {
            // console.log('4454545', e);
            this.openPopUp(e)
          } catch (error) {
          }
        });
      }

    } catch (error) {
      console.log("error", error)
    }

  }

  ngOnInit() {
    try {

      if (this.commonService.isBrowser()) {
        this.commoJsFn();
        $(window).on('popstate', $.proxy(function (event) {
          $('.back-main').removeClass('backdiv');
          //console.log("backurl",this.commonService.getPreviousUrl())
          var routeUrl = this.commonService.getPreviousUrl();
          $('body').addClass('backactive');
          $('body').addClass('bodybefore');
          $('.back-main').css('width', $(window).width());
          $('.back-main').css('height', $(window).height());
          $('.back-main').css('top', '0');
          setTimeout($.proxy(function () {
            $('.back-main').css('top', '-100%');
            this.router.navigate([routeUrl]);

          }, this), 500);
          setTimeout(function () {
            $('.back-main').removeAttr('style');
          }, 1000);
          setTimeout(function () {
            $('body').removeClass('backactive');
          }, 1500);
          setTimeout(function () {
            $('.jaben-animation').addClass('jabload');
            $('body').removeClass('bodybefore');
          }, 1600);
        }, this));
      }
    } catch (error) {

    }
  }

  openPopUp(e) {
    try {
      if (e.title == 'popup') {
        // $('.case-study-pop').show();
        this.caseType = e.value
        this.popupType = 'caspop'
      } else {
        // this.showLoader()
      }

    } catch (error) {

    }
  }

  showLoader() {
    try {
      if (this.commonService.isBrowser()) {
        $('.back-main').removeClass('backdiv');

        var routeUrl = this.commonService.getPreviousUrl();
        $('body').addClass('backactive');
        $('body').addClass('bodybefore');
        $('.back-main').css('width', $(window).width());
        $('.back-main').css('height', $(window).height());
        $('.back-main').css('top', '0');
        setTimeout($.proxy(function () {
          $('.back-main').css('top', '-100%');
          this.router.navigate([routeUrl]);
        }, this), 500);
        setTimeout(function () {
          $('.back-main').removeAttr('style');
        }, 1000);
        setTimeout(function () {
          $('body').removeClass('backactive');
        }, 1500);
        setTimeout(function () {
          $('.jaben-animation').addClass('jabload');
          $('body').removeClass('bodybefore');
        }, 1600);
      }
    } catch (error) {
    }
  }

  trueDelPopup() {
    try {
      if (this.commonService.isBrowser()) {
        $('.case-study-pop').hide();
        this.popupType = null;
      }
    } catch (error) {

    }
  }

  submitForm(f: NgForm) {
    var self = this;
    try {
      if (this.commonService.isBrowser()) {
        if (f.valid) {
          this.formGroup = f;
          this.showLoaderGif = true;

          f.value['form_type'] = 'casestudy';
          f.value['attachment_type'] = this.caseType;
          f.value['case_name'] = this.caseType;

          this.usname = f.value.name;

          // return false;

          let headers = new HttpHeaders({ 'content-Type': 'application/json' });

          this.httpClient.post('https://komodoapi.zusedigital.com/api/send_contact', f.value, { headers })
            .subscribe(data => {
              // console.log("Data", data)
              self.showThank = true;
              self.showLoaderGif = false;
              if (this.formGroup) {
                this.formGroup.resetForm();
              }
              self.popupType = 'thankpop';
            }, error => {
              console.log("error", error)
            })

        } else {
          console.log("error")
        }
      }
    } catch (error) {
      console.log('error', error)
    }

  }

  commoJsFn() {
    try {
      if (this.commonService.isBrowser()) {
        $('body').on('click', '.roster-popup', function () {
          $(this).fadeOut();
          $('body').removeClass('scrollhide');

          var pathname = window.location.pathname; // Returns path only (/path/example.html)
          var url = window.location.href;     // Returns full URL (https://example.com/path/example.html)
          var origin = window.location.origin;

          // console.log("window.location.href", `${origin}${pathname}`)

          window.history.replaceState(null, null, `${origin}${pathname}`);
        });

        $('body').on('click', '.sunray-popup.sunray-popup-form.case-study-pop', function () {
          $(this).hide();
        });

        $('body').on('click', '.roster-popup-inner', function (e) {
          e.stopPropagation();
        });

        $('body').on('click', '.sunray-popup.sunray-popup-form.case-study-pop .sunray-popup-inner', function (e) {
          e.stopPropagation();
        });
      }
    } catch (error) {

    }
  }

  triggerContactClick() {

  }

}
